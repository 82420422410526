@import "../../variables";
@import "../../../../static/styles/mixins";

.mobile-header {
  padding-top: env(safe-area-inset-top);

  &-full {
    &_centered {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  &--wrapper {
    cursor: default;
    height: calc(#{$header-height} + 1px);

    &_content {
      width: 100%;
      height: calc(#{$header-height} + 1px);
      display: flex;
      flex-direction: column;
    }
  }

  &--fake {
    width: calc(100% - 24px);
    height: calc(#{$header-height} + 1px);
    border-bottom: 1px solid rgba(0,0,0,0.07);
    box-sizing: border-box;
    margin: 0 12px;
  }

  &--shadow {
    box-shadow: 0 0 23px 0 rgba(0,0,0, .05), 0 0 0 1px rgba(0,0,0, .023);
  }

  &--main {
    width: 100vw;
    min-height: $header-height;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

    &_up {
      display: flex;
      background-color: #ffffffe0;
      height: 60px;
      z-index: 5;
      backdrop-filter: blur(2px);
    }

    &_bottom {
      height: calc(100vh - #{$header-height} - 1px);
      display: flex;
      flex-direction: column-reverse;
      z-index: 1000;
      position: fixed;
      bottom: 0;
      width: 100%;
      cursor: pointer;

      @include full {
        bottom: 50%;
      }

      &_content {
        width: 100%;
        display: flex;
        background-color: white;
        border-radius: 16px 16px 0 0;
        overflow: hidden;
        z-index: 1000;
        box-sizing: border-box;
        padding: 8px;
        animation-delay: 100ms !important;
        max-width: 700px;

        @include full {
          border-radius: 16px;
          padding: 16px;
        }
      }

      &_close {
        background-color: #00000024;
        width: 100%;
        flex: 1 1;
        z-index: 101;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    &_left {
      width: 60px;
      height: $header-height;
      margin: auto auto auto 0;
      position: relative;

      @include full {
        margin: auto 0 auto auto;
      }
    }

    &_center {
      flex: 1 1;
      height: $header-height;
      margin: auto 0 auto 0;
      position: relative;

      @include full {
        width: 600px;
        margin: 0;
        flex: inherit;
      }
    }

    &_right {
      width: 60px;
      height: $header-height;
      margin: auto 0 auto auto;
      position: relative;

      @include full {
        margin: auto auto auto 0;
      }
    }
  }
}
