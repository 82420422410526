@import "src/static/styles/mixins";
@import "src/components/ui/variables";

.EditVariations {
  &_centred {
    width: 90%;
    margin: auto;
  }

  &_button {
    width: 240px;
    height: 50px;
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 10px;
    background: #F8F5FF;
    font-size: 15px;
    display: flex;

    span {
      margin: auto 10px;
    }

    &_category {
      color: $primary;
      margin: auto 0;
    }
  }

  &_button:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.06), 0 0 0 1px rgba(0, 0, 0,  0.03);
  }

  &_item {
    padding: 6px;
    width: 100%;
    min-height: 100px;
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;

    @include full {
      flex-direction: row;
    }

    &_cover {
      width: 100%;
      min-height: 130px;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include full {
        width: 150px;
      }

    }

    &_info {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      @include full {
        width: 60%;
      }

      &_name {
        width: 100%;
        min-height: 20px;
        display: flex;
        flex-direction: column;

        span {
          margin: auto 20px;
          font-size: 12px;
          line-height: 16px;
          color: #9F97B8;
        }

        &_info {
          width: 100%;
          padding: 5px 20px;
          margin: auto;
          font-size: 13px;
          line-height: 16px;
        }
      }

      &_text {
        width: 100%;
        max-height: 40px;
        display: flex;

        span {
          margin: auto 0 auto 20px;
          font-size: 12px;
          line-height: 16px;
          color: #9F97B8;
        }

        &_info {
          margin: auto 0 auto 10px;
          font-size: 13px;
          line-height: 16px;
        }
      }


    }

    &_right {
      height: 40px;
      width: 100%;
      display: flex;
      flex-direction: row;

      @include full {
        flex-direction: column;
        width: 100px;
        min-height: 130px;
      }

      &_icon {
        width: 100%;
        height: 50%;
        display: flex;
        margin: auto;

        svg {
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }

      &_box {
        width: 100%;
        height: 50%;
        margin: auto;
        display: flex;
      }

    }
  }

  &_coverBox {
    width: 100%;
    height: 210px;
    display: flex;
    flex-direction: column;

    &_name {
      margin: auto;
      width: 100%;
      height: 40px;
      display: flex;
      font-size: 13px;
      line-height: 16px;
      color: #9F97B8;

      span {
        margin: auto 0;
      }
    }

    &_box {
      margin: auto;
      width: 100%;
      height: 130px;
      background-color: #F8F5FF;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 10px;
      display: flex;

      svg {
        color: $primary;
        margin: auto;
      }
    }

    &_download {
      margin: auto;
      width: 100%;
      height: 40px;
      display: flex;

      span {
        margin: auto 0;
        font-size: 16px;
        line-height: 20px;
        color: $primary;
      }
    }
  }

  &_switch {
    width: 100%;
    height: 50px;
    display: flex;

    span {
      margin: auto auto auto 0;
      font-size: 17px;
      line-height: 22px;
    }

    &_box {
      width: 50px;
      height: 50px;
      display: flex;
    }
  }

  &_carouselItem {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: #F8F5FF;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;

    svg {
      margin: auto;
      color: $primary;
    }
  }
}

.EditVariationsHeader {
  width: 100%;

  @include full {
    max-width: 700px;
    margin: auto;
  }

  &_header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    &_left {
      width: 20%;
      height: 50px;
      display: flex;

      svg {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }

    &_center {
      width: 60%;
      height: 50px;
      display: flex;

      span {
        margin: auto;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
      }
    }

    &_right {
      width: 20%;
      height: 50px;
      display: flex;

      &_icon {
        cursor: pointer;
        margin: auto 0 auto auto;
        display: flex;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: $primary;

        svg {
          margin: auto;
          color: #F8F5FF;
        }
      }
    }


  }
}
