@import "src/static/styles/mixins";

.Modals {
  position: fixed;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1100;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  padding: 48px 0;
  box-sizing: border-box;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;

  .Modals__box {
    background: rgba(0, 0, 0, 0.0);
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.08), 0 0 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px 16px 0 0;
    box-sizing: border-box;
    background-color: white;
    flex: 0 0 auto;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 170px;
    left: 0;
    right: 0;
    bottom: 0;

    @include full {
      width: 700px;
      margin: auto;
      top: 140px;
      bottom: 40px;
      border-radius: 16px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      cursor: pointer;
      position:absolute;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: #f3f3f3;
      border: 3px solid white;
      border-left:0;
    }

    &:hover::-webkit-scrollbar-thumb:vertical {
      background-color:#eee;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color:#ccc !important;
    }
  }

  .Modals__box__close {
    width: 100%;
    height: 200px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    display: flex;
  }

  .icon_close {
    margin: auto auto 35px auto;
    cursor: pointer;
    background: url('../../../../components/ui/universal/Modals/assets/close.svg') no-repeat center;
    background-size: 24px;
    width: 60px;
    height: 60px;
    color: white;

    @include full {
      margin: auto auto 75px auto;
    }
  }

  &.Modals__noSpacing {
    .Modals__box {
      padding: 0;
    }
  }
}

.Modals:empty {
  display: none;
}

.Modals__header {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  justify-content: center;

  .Modals__noSpacing & {
    margin-top: 32px;
  }
}

.Modals::-webkit-scrollbar {width: 6px;cursor:pointer;position:absolute;}
.Modals::-webkit-scrollbar-track-piece {background-color:transparent;}
.Modals::-webkit-scrollbar-thumb:vertical {background-color: #f3f3f3;border:2px solid #fff;border-left:0;}
.Modals:hover::-webkit-scrollbar-thumb:vertical{background-color:#eee;}
.Modals::-webkit-scrollbar-thumb:active{background-color:#ccc !important;}
