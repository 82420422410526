.Radio {
  display: block;
}

.Radio__input {
  display: none;
}

.Radio__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Radio__input:disabled ~ .Radio__container {
  opacity: .6;
}

.Radio__icon {
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
}

.Radio__icon::after {
  position: absolute;
  content: '';
  display: block;
  border-radius: 50%;
  box-sizing: border-box;
}

.Radio__content {
  line-height: 20px;
  font-size: 16px;
  flex-grow: 1;
  max-width: 100%;
  color: var(--text_primary);
}

.Radio__description {
  font-size: 13px;
  line-height: 15px;
  color: var(--text_secondary);
}

.Radio--ios {
  padding: 10px 12px;
}

.Radio--ios .Radio__container {}

.Radio--ios .Radio__icon {
  width: 22px;
  height: 22px;
  border: 2px solid gray;
  margin: 1px 12px 1px 1px;
  transition: border-color .15s cubic-bezier(.36, .66, .04, 1);
}

.Radio--ios .Radio__input:checked ~ .Radio__container .Radio__icon {
  border-color: red;
}

.Radio--ios .Radio__icon::after {
  width: 14px;
  height: 14px;
  left: 2px;
  top: 2px;
  transform: scale(.3);
  transition: transform .15s cubic-bezier(.36, .66, .04, 1);
}

.Radio--ios .Radio__input:checked ~ .Radio__container .Radio__icon::after {
  background-color: red;
  transform: scale(1);
}
