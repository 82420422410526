@import "variables";
@import "mixins";

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat';
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 6px;
    cursor: pointer;
    position:absolute;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: #f3f3f3;
    border: 3px solid white;
    border-left:0;
  }

  &:hover::-webkit-scrollbar-thumb:vertical {
    background-color:#eee;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color:#ccc !important;
  }
}

.scroll_hidden {
  width: calc(100% - 6px);
}

.mobile.scroll_hidden {
  width: 100% !important;
}

.w-100 {
  width: 100%;
}

.Main__overlow {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  box-sizing: border-box;
  display: flex;
  padding: 4px 0;

  .tab-bar--wrapper {
    width: auto !important;
  }

  &::-webkit-scrollbar {width: 0;cursor:pointer;position:absolute;}
  &::-webkit-scrollbar-track-piece {background-color:transparent;}
  &::-webkit-scrollbar-thumb:vertical {background-color: transparent;border: 0 solid transparent;border-left:0;}
  &:hover::-webkit-scrollbar-thumb:vertical{background-color: transparent;}
  &::-webkit-scrollbar-thumb:active{background-color: transparent !important;}
}

.Page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include full {
    max-width: 700px;
    margin: 0 auto;
  }

  &-inner {
    flex: 1 1;
    position: relative;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.display_flex {
  display: flex;
}

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  outline: none;
}

[hidden] {
  display: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}

