.slider {
  .rc-slider-track {
    background-color: #a380ff;
  }

  .rc-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -10px;
    background-color: #a380ff;
    border: 2px solid #a380ff;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: none;
    border-color: white;
  }


  .rc-slider-handle:hover {
    border-color: white;
  }

  .rc-slider-handle:focus {
    border-color: white;
  }

  .rc-slider-handle:active {
    box-shadow: 0 0 5px #a380ff;
    border-color: white;
  }

  .rc-slider-handle-click-focused {
    border-color: white;
  }
}